import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Page.jsx";
import Divider from '../components/atoms/Divider';
import Spacer from '../components/atoms/Spacer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Requirements for Checklist app`}</h2>
    <ul>
      <li parentName="ul">{`Minimum iOS version: 14.0`}</li>
      <li parentName="ul">{`Best with iPad`}</li>
    </ul>
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`Is there any prebuilt checklist for my "aircraft-make-model"?`}</h2>
    <p>{`No there is not. You need to build your own. You can find a guide on how to build a checklist for yourself here: `}<a parentName="p" {...{
        "href": "https://checklist.tabbythecat.com/how-to/"
      }}>{`https://checklist.tabbythecat.com/how-to/`}</a>{`
and also you can ask for help to find a checklist for your aircraft on Discord channel.`}</p>
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`I found a bug. What should I do?`}</h2>
    <p>{`Just send an email to `}<a parentName="p" {...{
        "href": "mailto:stevenjselcuk@gmail.com"
      }}>{`stevenjselcuk@gmail.com`}</a>{` with the following information:`}</p>
    <br />
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The version of checklist app you are using`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Device model/generation and iOS/iPadOS version`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The error message (if possible)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Screenshot (if possible)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Explain what you were doing when the error occurred`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Explaination for reproducing the error`}</p>
      </li>
    </ul>
    <br />
    <Divider mdxType="Divider" />
    <br />
    <h2>{`I have an feature request!`}</h2>
    <p>{`Check roadmap section.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      